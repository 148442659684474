import React from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
  dotClassName?: string;
  white?: boolean;
}

const Loader = ({ className, dotClassName, white, ...props }: Props) => {
  const dotClass = cn('loader__dot', dotClassName, { 'bg-white': white });
  return (
    <div className={cn(`loader`, className)} {...props}>
      <div className={dotClass} />
      <div className={dotClass} />
      <div className={dotClass} />
    </div>
  );
};

export default Loader;
