import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { PageProps } from 'gatsby';

import Loader from '../views/Loader';

/**
 * Po inicializaci chatu lze volat tyto js eventy:
 * - přidání kontextových proměnných do chatu:
 * window.dispatchEvent(new CustomEvent('chatbot-ew-context-set', {
 * detail: {
 * $name: { value: 'Alenka Zelená' },
 * $cokoliv: { value: '6546546' }
 * } }));
 * - ukonceni diskuse, zavreni chatu
 * window.dispatchEvent(new CustomEvent('ew-end-discussion'));
 * - otevreni chatu
 * window.dispatchEvent(new CustomEvent('ew-open-window'));
 * - minimalizace chatu
 * window.dispatchEvent(new CustomEvent('ew-hide-window'));
 * - otevreni, pokud je minimalizovan / minimalizace pokud je otevren
 * window.dispatchEvent(new CustomEvent('ew-toggle-window'));
 * - zavreni a restart chatu
 * window.dispatchEvent(new CustomEvent('ew-close-and-restart-chat'));
 * Ze je chatbot inicializovan jde zjistit takto:
 * - reakce na event nacteni chatbota
 * window.addEventListener('chatbot-loaded', function() {
 * // chatbot je nacteny, muze se ovladat eventy viz vyse
 *
 * @param props
 * @constructor
 */
export default function ChatPage(props: PageProps) {
  if (!props?.children) {
    console.log('[chat.tsx] No children props found');
  }

  const [loading, setLoading] = useState(true); // Loading state for the spinner
  const [logMessages, setLogMessages] = useState([]);

  const addLogMessage = message => {
    //  also add timestmap readable
    setLogMessages(prevMessages => [...prevMessages, `${new Date().toLocaleTimeString()} - ${message}`]);
  };

  useEffect(() => {
    window.onload = function () {
      console.log('Page Loaded');
      addLogMessage('Page Loaded');
    };

    window.onerror = (message, source, lineNo, colNo, _error) => {
      const errorMsg = `Error: ${message} at ${source}:${lineNo}:${colNo}`;
      console.error(errorMsg);
      addLogMessage(errorMsg);
    };

    addLogMessage('Page Loaded');

    window.addEventListener('chatbot-loaded', () => {
      addLogMessage('Chatbot script loaded successfully.');
      setLoading(false);
      window.dispatchEvent(new Event('ew-open-window'));
      addLogMessage('Chatbot opened.');
    });

    // Add event listener for when the chat is minimized
    window.addEventListener('ew-hide-window', function () {
      console.log('Chat minimized, returning to app (universal link)...');
      addLogMessage('Chat minimized, returning to app (universal link)...');

      // Redirect to your Firebase Universal Link (change the URL to your actual link)
      // https://console.firebase.google.com/u/1/project/smilecar-150816/durablelinks/links/https:~2F~2Fhoppygo.page.link
      window.location.href = 'https://hoppygo.page.link/user-profile';
    });

    window.addEventListener('ew-close-and-restart-chat', function () {
      window.location.reload();
      console.log('Chat closed and restarted');
      addLogMessage('Chat closed and restarted');
    });

    return () => {
      window.removeEventListener('chatbot-loaded', () => {
        console.log('removed listener chatbot-loaded');
        addLogMessage('removed listener chatbot-loaded');
      });

      window.removeEventListener('ew-hide-window', () => {
        console.log('removed listener ew-hide-window');
        addLogMessage('removed listener ew-hide-window');
      });

      window.removeEventListener('ew-close-and-restart-chat', () => {
        console.log('removed listener ew-close-and-restart-chat');
        addLogMessage('removed listener ew-close-and-restart-chat');
      });
    };
  }, []);

  return (
    <>
      {loading ? (
        <main className="container main-container container--top container--bottom">
          <Loader />
        </main>
      ) : null}

      {/* visible only in dev mode, not on stage */}
      {process.env.NODE_ENV !== 'production' ? (
        <div className="logger">
          <h3>Logger accessible in dev/stage</h3>
          <ul>
            {logMessages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
}

export const Head = () => {
  useEffect(() => {
    // on load of the page, try to load the script again (only once) in case the page renders or it was somehow stuck earlier
    window.onload = function () {
      // Check if the script has already been added
      if (!document.getElementById('coworkers')) {
        const ewConfig = {
          customButton: true,
          hashForOpenAtFirstVisit: 'chatbot-open',
          hashForceOpen: true,
          instanceId: 2,
          openStateStorage: 'LOCAL_STORAGE',
          openedAtFirstVisit: true,
          openedAtFirstVisitMobile: true,
          theme: 'chatbot-artin',
          tokenStorage: 'LOCAL_STORAGE',
          url: 'https://hoppygo.bot.coworkers.ai',
          urlPrefix: 'https://hoppygo.bot.coworkers.ai',
        };
        (function (w, d, c) {
          const p = ['vendor.js', 'app.js'];
          for (let i = 0; i < p.length; i++) {
            const s = d.createElement('script');
            s.src = c.url + '/ew/' + p[i];
            s.id = 'coworkers'; // Add id to prevent duplicates
            d.getElementsByTagName('head')[0].appendChild(s);
          }
        })(window, document, ewConfig);
      }
    };
  }, []);

  return (
    <script async defer id="coworkers">
      {`
          // Load the script only once on the page
          if (!document.getElementById('coworkers')) {
                    var ewConfig = {
            "tokenStorage": "LOCAL_STORAGE",
            "customButton": true,
            "openStateStorage": "LOCAL_STORAGE",
            "openedAtFirstVisit": true,
            "openedAtFirstVisitMobile": true,
            "hashForceOpen": true,
            "hashForOpenAtFirstVisit": "chatbot-open",
            "theme": "chatbot-artin",
            "instanceId": 2,
            "url": "https://hoppygo.bot.coworkers.ai",
            "urlPrefix": "https://hoppygo.bot.coworkers.ai"
          };
          (function (w, d, c) {
            var p = ['vendor.js', 'app.js'];
            for (var i = 0; i < p.length; i++) {
              var s = d.createElement('script');
              s.src = c.url + '/ew/' + p[i];
              d.getElementsByTagName('head')[0].appendChild(s);
            }
          })(window, document, ewConfig);
          }
        `}
    </script>
  );
};
